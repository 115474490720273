'use client';

import { usePathname } from 'next/navigation';

import { deconstructPathname } from '../utils/UrlUtils';

export function useLocalisation() {
    const pathname = usePathname() ?? '';
    return deconstructPathname(pathname);
}
