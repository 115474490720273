import { lookup } from 'country-data-list';

import { SHIPPABLE_COUNTRIES } from '../constants/countries';
import { isInArray } from '../utils/TypeUtils';

export function getCountryCallingCode(countryCode: string): string {
    return lookup.countries({ alpha2: countryCode })[0].countryCallingCodes[0];
}

export const isShippableCountry = (input: string) => isInArray(input, SHIPPABLE_COUNTRIES);
