'use client';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ReactNode } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';

import { i18nOptions } from '../config/i18n.config';
import { useLocalisation } from '../hooks/useLocalisation';

i18n.use(LanguageDetector).use(initReactI18next);

// initialize if not already initialized
if (!i18n.isInitialized) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n.init(i18nOptions);
}

type Props = {
    children: ReactNode;
};

export function LanguageProvider({ children }: Props) {
    const { language } = useLocalisation();

    return <I18nextProvider i18n={i18n.cloneInstance({ lng: language ?? undefined })}>{children}</I18nextProvider>;
}
