import { SupportedLanguage, isSupportedLanguage } from '../config/i18n.config';
import { SHIPPABLE_COUNTRIES, ShippableCountry } from '../constants/countries';
import { DEFAULT_COUNTRY, DEFAULT_LANGUAGE } from '../constants/localization';
import { isShippableCountry } from '../formatters/countries';

interface UrlConstruct<Country extends string | undefined, Language extends string | undefined> {
    path: string;
    shopifyLanguage?: string;
    isLocalisationValid: boolean;
    language: Language;
    locale: Country;
}

interface DeconstructOptions<Country extends string | undefined, Language extends string | undefined> {
    path?: string;
    defaultCountry: Country;
    defaultLanguage: Language;
    formatLanguage?: (l: Language) => Language;
    formatCountry?: (c: Country) => Country;
}

export function internalDeconstructPathname<Country extends string | undefined, Language extends string | undefined>({
    path,
    defaultCountry,
    defaultLanguage,
    formatCountry = (language) => language,
    formatLanguage = (country) => country,
}: DeconstructOptions<Country, Language>): UrlConstruct<Country, Language> {
    if (!path || !path.startsWith('/')) {
        return {
            locale: defaultCountry,
            language: defaultLanguage,
            shopifyLanguage: defaultLanguage?.toUpperCase(),
            path: '',
            isLocalisationValid: false,
        };
    }

    const parts = path.split('/');
    if (parts.length < 3) {
        return {
            locale: defaultCountry,
            language: defaultLanguage,
            shopifyLanguage: defaultLanguage?.toUpperCase(),
            path,
            isLocalisationValid: false,
        };
    }

    const language = formatLanguage(parts[1].toLowerCase() as Language);
    const locale = formatCountry(parts[2].toUpperCase() as Country);

    return {
        language,
        shopifyLanguage: language?.toUpperCase(),
        locale,
        path: '/' + parts.slice(3).join('/'),
        isLocalisationValid: language === parts[1].toLowerCase() && locale === parts[2].toUpperCase(),
    };
}

export function getSafeUrl(url: string) {
    try {
        return new URL(url);
    } catch {
        return undefined;
    }
}

export function deconstructPathname(pathname?: string) {
    return internalDeconstructPathname<ShippableCountry, SupportedLanguage>({
        path: pathname,
        defaultCountry: DEFAULT_COUNTRY,
        defaultLanguage: DEFAULT_LANGUAGE,
        formatCountry: (country) => (SHIPPABLE_COUNTRIES.includes(country) ? country : DEFAULT_COUNTRY),
        formatLanguage: (language) => (isSupportedLanguage(language) ? language : DEFAULT_LANGUAGE),
    });
}

export function constructPathname({ path, params }: { path: string; params?: URLSearchParams }) {
    return `${path}${[...(params?.values() ?? [])].length ? `?${params?.toString()}` : ''}`;
}

export const ensureIsLanguage = (language?: string): SupportedLanguage => {
    if (!language) {
        return DEFAULT_LANGUAGE;
    }

    if (isSupportedLanguage(language)) {
        return language as SupportedLanguage;
    }
    return DEFAULT_LANGUAGE;
};

export const ensureIsLocale = (locale?: string): ShippableCountry => {
    if (!locale) {
        return DEFAULT_COUNTRY;
    }

    if (isShippableCountry(locale)) {
        return locale as ShippableCountry;
    }
    return DEFAULT_COUNTRY;
};
