'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useMemo } from 'react';
import { constructPathname, deconstructPathname } from '../utils';
import { omittedParams, SearchParam, type TypedParamsStructure, type UrlParam } from '../constants/urlParams';

type TypedParamsNavigationStructure = Partial<Record<keyof TypedParamsStructure, string | null>>;

export function useUrlParams() {
    const searchParams = useSearchParams();
    const pathname = usePathname() ?? undefined;
    const router = useRouter();

    const isParam = useCallback(
        ({ name, value, delimiter }: { name: string; value: string; delimiter?: string }) =>
            !!searchParams
                ?.get(name)
                ?.split(delimiter ?? ',')
                .filter(Boolean)
                .includes(value),
        [searchParams],
    );

    const params = useMemo(
        () =>
            Object.values(SearchParam).reduce<TypedParamsStructure>((total, key) => {
                const value = searchParams?.get(key);
                return {
                    ...total,
                    [key]: value ? value : undefined,
                };
            }, {}),
        [searchParams],
    );

    const buildUrl = useCallback(
        (options: { path?: string; params?: TypedParamsNavigationStructure; omitFilterParams?: boolean }) => {
            const { path, params: _params = {}, omitFilterParams = false } = options;

            const _omittedParams = omitFilterParams ? omittedParams : {};

            const newParamsBase = { ...params, ..._omittedParams, ..._params };
            const newParamsPurged = Object.keys(newParamsBase).reduce<TypedParamsStructure>((params, key) => {
                const value = newParamsBase[key as keyof TypedParamsStructure];
                if (value === null || value === undefined || value === '') return params;
                return {
                    ...params,
                    [key]: value,
                };
            }, {});

            const urlConstruct = deconstructPathname(pathname);
            return {
                url: constructPathname({
                    ...urlConstruct,
                    path: path ?? urlConstruct.path,
                    params: new URLSearchParams(newParamsPurged),
                }),
            };
        },
        [params, pathname],
    );

    const setParam = useCallback(
        ({ name, value }: UrlParam): void => router.replace(buildUrl({ params: { [name]: value } }).url),
        [buildUrl, router],
    );

    const unsetParam = useCallback((name: UrlParam['name']): void => setParam({ name, value: undefined }), [setParam]);

    return { isParam, searchParams: searchParams ?? undefined, setParam, unsetParam, params, pathname, buildUrl };
}
