'use client';

import { useEffect, ReactElement } from 'react';

export function CypressPageRenderedProvider({ children }: { children: ReactElement }) {
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        globalThis.pageRendered = true;
    });
    return <>{children}</>;
}
