import { MixpanelTrackProps } from './interfaces';
import { useMixpanel } from './MixpanelProvider';

export function MixpanelTrack({ children, eventName, className, properties, disabled = false }: MixpanelTrackProps) {
    const mixpanel = useMixpanel();
    const handleClick = () => {
        if (!disabled && !!eventName) {
            mixpanel?.track(eventName, properties);
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <span role="button" onClick={handleClick} tabIndex={0} className={`contents ${className}`}>
            {children}
        </span>
    );
}
