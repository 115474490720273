'use client';

import { useMoney } from '@shopify/hydrogen-react';
import { CurrencyCode, MoneyV2 } from '@shopify/hydrogen-react/storefront-api-types';

export function moneyHasCustomCurrency(currencyCode: CurrencyCode): boolean {
    return (
        currencyCode === ('SEK' as CurrencyCode) ||
        currencyCode === ('DKK' as CurrencyCode) ||
        currencyCode === ('NOK' as CurrencyCode)
    );
}

export function useMoneyWithCurrency(money: MoneyV2): string {
    const price = useMoney(money);
    if (moneyHasCustomCurrency(price.currencyCode)) return `${price.currencyCode} ${price.amount}`;
    return price.localizedString;
}
