import { SUPPORTED_LANGUAGES, SupportedLanguage, resources } from '../config/i18n.config';
import { DEFAULT_LANGUAGE } from '../constants/localization';

interface GetTranslationProps {
    language?: SupportedLanguage;
}

export function getTranslation({ language = DEFAULT_LANGUAGE }: GetTranslationProps = {}) {
    if (!SUPPORTED_LANGUAGES.includes(language)) {
        return resources.en.translation;
    }
    return resources[language].translation;
}
