'use client';
import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';

export type AddressLookupRequest = { country: string; postcode: string; town?: string };
export type AddressLookupResponse = { town: string };
export type AddressLookupAsyncMutation = UseMutateAsyncFunction<
    AddressLookupResponse[],
    Error,
    AddressLookupRequest,
    unknown
>;

export function useAddressLookupMutation({
    onSuccess,
    onError,
}: {
    onSuccess?: (data: AddressLookupResponse[]) => void;
    onError?: (e: Error) => void;
}) {
    return useMutation<AddressLookupResponse[], Error, AddressLookupRequest>(
        ['address-validataion'],
        async ({ country, postcode, town }) => {
            const response = await fetch('/api/address/lookup', {
                method: 'POST',
                body: JSON.stringify({ country, postcode, town }),
            });
            if (!response.ok)
                throw new Error(
                    `Could not find city from supplied arguments: ${JSON.stringify({ country, postcode, town })}`,
                );
            const { searchResults } = await response.json();
            return searchResults;
        },
        {
            onSuccess,
            onError,
        },
    );
}
