'use client';
import { ProductPrice } from '@shopify/hydrogen-react';
import { MoneyProps } from '@shopify/hydrogen-react/dist/types/Money';
import { ProductPriceProps } from '@shopify/hydrogen-react/dist/types/ProductPrice';
import { CurrencyCode } from '@shopify/hydrogen-react/dist/types/storefront-api-types';

import { moneyHasCustomCurrency } from '../hooks/useMoneyWithCurrency';

export type ProductPriceWrappedProps = ProductPriceProps &
    Omit<MoneyProps<any>, 'data' | 'measurement'> & { className?: string };

export function ProductPriceWrapped(props: ProductPriceWrappedProps) {
    const currencyCode =
        props.data.priceRange?.minVariantPrice?.currencyCode ||
        props.data.variants?.edges?.[0]?.node?.price?.currencyCode;

    if (moneyHasCustomCurrency(currencyCode as CurrencyCode)) {
        if (
            !props.data.priceRange?.minVariantPrice?.amount &&
            !(props.data.variants?.edges?.[0]?.node?.price?.amount && props.priceType !== 'compareAt')
        ) {
            return null;
        }
        return (
            <div className="flex flex-row gap-2">
                <span className={props.className}>{String(currencyCode)}</span>
                <ProductPrice
                    {...props}
                    className={`${props.className}${props.priceType === 'compareAt' ? ' strikethrough' : ''}`}
                    withoutCurrency
                    suppressHydrationWarning
                />
            </div>
        );
    }
    return (
        <ProductPrice
            {...props}
            className={`${props.className}${props.priceType === 'compareAt' ? ' strikethrough' : ''}`}
            suppressHydrationWarning
        />
    );
}
