import { FirebaseOptions } from 'firebase/app';
import { Environment } from '../constants';

export function getFirebaseConfig(environment: Environment = Environment.PROD): FirebaseOptions {
    switch (environment) {
        case Environment.DEV:
        case Environment.TEST:
            return {
                apiKey: 'AIzaSyBbiDYRO4kj8EcWPAUmoEPoqF375ERhDkQ',
                authDomain: 'velroq-dev.firebaseapp.com',
                projectId: 'velroq-dev',
                storageBucket: 'velroq-dev.appspot.com',
                messagingSenderId: '530506951561',
                appId: '1:530506951561:web:739e486c6627efff96cf58',
            };
        case Environment.PROD:
            return {
                apiKey: 'AIzaSyCqKwFDM1ebb9zfrkJb0DW4LcUGJrai05M',
                authDomain: 'velroq-prod.firebaseapp.com',
                projectId: 'velroq-prod',
                storageBucket: 'velroq-prod.appspot.com',
                messagingSenderId: '532647263171',
                appId: '1:532647263171:web:fbcb461dc436a85579db76',
            };
    }
}
