import { Route } from '../config/routing';

export function formatRoute(
    route: Route,
    { context, params }: { context?: Record<string, string | number>; params?: Record<string, string> } = {},
) {
    let result: string = route;
    if (context) {
        for (const [key, value] of Object.entries(context)) {
            result = result.replaceAll(`{{${key}}}`, `${value}`);
        }
    }
    const formattedParams = params
        ? `?${Object.entries(params)
              .map(([key, value]) => `${key}=${value}`)
              .join('&')}`
        : '';
    return result + formattedParams;
}
