import { BUNDLE_TAG_NAME } from './useAvailability';

const TAG_REGEX = /^((mb)|(mm)|(my)|(pc)|(kt)|(st)):/;

export interface FormatTagsProps {
    tags?: string[];
    customTags?: { id: string; name: string }[];
    machineCompatibility?: {
        model: { id: string; name: string };
    }[];
    categories?: { title: string }[];
}

export function formatTags({ tags, customTags, machineCompatibility, categories }: FormatTagsProps): string[] {
    let results = tags?.filter((tag) => !TAG_REGEX.test(tag) && tag !== BUNDLE_TAG_NAME) ?? [];

    if (tags && customTags) {
        const tagIds = customTags?.map(({ id }) => id);
        results = results.filter((tag) => !tagIds.includes(tag));
        for (const { id, name } of customTags) {
            if (tags.find((tag) => tag === id)) results.push(name);
        }
    }

    if (machineCompatibility) {
        for (const machine of machineCompatibility) {
            if (machine.model.name) results.push(machine.model.name);
        }
    }

    if (categories?.length) {
        for (const { title } of categories) {
            results.push(title);
        }
    }

    return results;
}
