'use client';

import mixpanel from 'mixpanel-browser';
import { FunctionComponent, ReactNode, createContext, useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const MixpanelContext = createContext<typeof mixpanel>(undefined!);

export interface MixpanelProviderProps {
    children: ReactNode;
    mixpanelConfig: { token: string; debug: boolean };
}

export function MixpanelProvider({ children, mixpanelConfig }: MixpanelProviderProps) {
    const { token, debug } = mixpanelConfig;

    mixpanel.init(token, { debug });

    return <MixpanelContext.Provider value={mixpanel}>{children}</MixpanelContext.Provider>;
}

export const useMixpanel = () => useContext(MixpanelContext);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const withMixpanel = (Component: FunctionComponent) => (props: any) =>
    <MixpanelContext.Consumer>{(mixpanel) => <Component mixpanel={mixpanel} {...props} />}</MixpanelContext.Consumer>;
