'use client';

import { Money } from '@shopify/hydrogen-react';
import { MoneyProps } from '@shopify/hydrogen-react/dist/types/Money';
import { CurrencyCode } from '@shopify/hydrogen-react/dist/types/storefront-api-types';

import { moneyHasCustomCurrency } from '../hooks/useMoneyWithCurrency';

export function MoneyWrapped(props: MoneyProps<any> & { className?: string }) {
    const currencyCode = props.data.currencyCode;

    if (moneyHasCustomCurrency(currencyCode as CurrencyCode)) {
        if (!props.data.amount) return null;
        return (
            <span className="flex flex-row gap-2 float-right">
                <span className={props.className}>{String(currencyCode)}</span>
                <Money {...props} withoutCurrency suppressHydrationWarning />
            </span>
        );
    }

    return <Money {...props} suppressHydrationWarning />;
}
