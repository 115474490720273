import { useMemo } from 'react';

export const BUNDLE_TAG_NAME = 'bundle';
export interface UseAvailabilityProps {
    availableForSale: boolean;
    quantityAvailable: number;
    tags: string[];
}

export enum Availability {
    IN_STOCK = 'IN_STOCK',
    OUT_OF_STOCK = 'OUT_OF_STOCK',
    NOT_FOR_SALE = 'NOT_FOR_SALE',
}

export function useAvailability({ availableForSale, quantityAvailable, tags }: UseAvailabilityProps): Availability {
    return useMemo(
        () =>
            !availableForSale
                ? Availability.NOT_FOR_SALE
                : quantityAvailable > 0 || tags.includes(BUNDLE_TAG_NAME)
                  ? Availability.IN_STOCK
                  : Availability.OUT_OF_STOCK,
        [availableForSale, quantityAvailable, tags],
    );
}
