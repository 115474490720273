import { initializeApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import { getCurrentEnv } from '../config/environment';
import { getFirebaseConfig } from '../config/firebase';

export async function uploadMissingPartPicture(file: File) {
    // Initialize Firebase
    const app = initializeApp(getFirebaseConfig(getCurrentEnv()));

    // Initialize Cloud Storage and get a reference to the service
    const storage = getStorage(app);

    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    const name = 'missing-part-picture-' + Date.now();

    const storageRef = ref(storage, `/missing-parts/${currentYear}/${currentMonth}/${name}`);

    await uploadBytes(storageRef, file);
    console.info('Upload missing part picture: Uploaded a blob or file!');
    const url = await getDownloadURL(storageRef);
    console.info('Upload missing part picture: Download url:', url);
    return url;
}
