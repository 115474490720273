export function extractId(id?: string | null) {
    return id?.split('?').at(0);
}

export const FormatShopifyId = {
    customer: (id: string) => `gid://shopify/Customer/${id}`,
    order: (id: string) => `gid://shopify/Order/${id}`,
    product: (id: string) => `gid://shopify/Product/${id}`,
    productVariant: (id: string) => `gid://shopify/ProductVariant/${id}`,
};
