import { ClientConfig } from '@sanity/client';
import { getCurrentEnv } from './environment';

export type SanityConfigProps = Pick<ClientConfig, 'apiVersion' | 'dataset' | 'projectId' | 'token'> & {
    cdnBaseUrl: string;
};

export async function getSanityConfig() {
    const sanity: SanityConfigProps = {
        apiVersion: 'v2022-05-01',
        dataset: getCurrentEnv() === 'production' ? 'production' : 'development',
        projectId: 'pic6vvum',
        cdnBaseUrl: 'https://cdn.sanity.io/images',

        // Merely a read token, no write access
        token: 'skpvb5lbUUqg23lix1675HijiNkRNTva2rgLzmUI6M4lfxn2fD2ix15FWBefPitneenzZOtMd13sVaBXFDgDlD74MWMhSGWEVvqXwmNtRYUUALEHUXwrvlMYz37XehK3jcWq4deZjRWhS0GJag803oChoCsOdoDCduSHbHvBAk7PZXMFZQyh',
    };

    return sanity;
}
