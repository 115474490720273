'use client';

import { useEffect, useRef, useState } from 'react';

// Tailwind default config
const screens = {
    sm: '640px',
    // => @media (min-width: 640px) { ... }

    md: '768px',
    // => @media (min-width: 768px) { ... }

    lg: '1024px',
    // => @media (min-width: 1024px) { ... }

    xl: '1280px',
    // => @media (min-width: 1280px) { ... }

    '2xl': '1536px',
    // => @media (min-width: 1536px) { ... }
};

export function useTailwindBreakpoint(breakpoint: keyof typeof screens, defaultValue = false) {
    const [match, setMatch] = useState(() => defaultValue);
    const matchRef = useRef(defaultValue);

    useEffect(() => {
        function track() {
            const value = (screens[breakpoint] as string) ?? '999999px';
            const query = window.matchMedia(`(min-width: ${value})`);
            matchRef.current = query.matches;
            if (matchRef.current !== match) {
                setMatch(matchRef.current);
            }
        }

        track();
        window.addEventListener('resize', track);
        return () => window.removeEventListener('resize', track);
    }, [breakpoint, match]);

    return match;
}
