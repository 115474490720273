export enum SearchParam {
    BRAND = 'brand',
    MODEL = 'model',
    YEAR = 'year',
    TAGS = 'tags',
    VENDORS = 'vendors',
    AVAILABILITY = 'availability',
    SEARCH = 'search',
    PAGINATION = 'pagination',
    SORTING = 'sorting',
    BACK = 'backToPath',
    CUSTOMER_ID = 'customerId',
    RESET_TOKEN = 'resetToken',
    EMAIL = 'email',
    VAT = 'vat',
    ACTIVATION_TOKEN = 'activationToken',
    REDIRECT = 'redirect',
    LOADING = 'loading',
    PRODUCT_ADDED = 'productAdded',
    CONNECT = 'connect',
    DISCOUNTED = 'discounted',
}

type FilterParams = Extract<
    SearchParam,
    | SearchParam.SEARCH
    | SearchParam.TAGS
    | SearchParam.VENDORS
    | SearchParam.PAGINATION
    | SearchParam.PRODUCT_ADDED
    | SearchParam.CONNECT
    | SearchParam.CUSTOMER_ID
    | SearchParam.ACTIVATION_TOKEN
    | SearchParam.DISCOUNTED
>;
type OmittedParamsStructure = Record<FilterParams, null>;

export const omittedParams: OmittedParamsStructure = {
    [SearchParam.SEARCH]: null,
    [SearchParam.PRODUCT_ADDED]: null,
    [SearchParam.CONNECT]: null,
    [SearchParam.TAGS]: null,
    [SearchParam.VENDORS]: null,
    [SearchParam.DISCOUNTED]: null,
    [SearchParam.PAGINATION]: null,
    [SearchParam.CUSTOMER_ID]: null,
    [SearchParam.ACTIVATION_TOKEN]: null,
} as const;

export type TypedParamsStructure = Partial<Record<SearchParam, string>>;

export type UrlParam = {
    name: SearchParam;
    value?: string;
};
