'use client';
import { useMutation } from '@tanstack/react-query';

export type ProvinceSearchRequest = { country: string };
export type ProvinceSearchResponse = {
    countryCode: string;
    provinces: {
        code: string;
        name: string;
    }[];
};

export function useProvinceSearchMutation({
    onSuccess,
    onError,
}: {
    onSuccess?: (data: ProvinceSearchResponse) => void;
    onError?: (e: Error) => void;
}) {
    return useMutation<ProvinceSearchResponse, Error, ProvinceSearchRequest>(
        ['province-search'],
        async ({ country }) => {
            const response = await fetch('/api/address/provinces', {
                method: 'POST',
                body: JSON.stringify({ country }),
            });
            if (!response.ok)
                throw new Error(`Could not find provinces from supplied country: ${JSON.stringify({ country })}`);
            return response.json();
        },
        {
            onSuccess,
            onError,
        },
    );
}
