import * as yup from 'yup';

import { SHIPPABLE_COUNTRY_NAMES, ShippableCountry, ZIP_EXAMPLE } from '../constants';
import { TranslationFunction } from '../translations';
import { countryVatRegex, formatPhone, vatRegex, VatRegexParams } from '../formatters/';
import { MinVatSchema, PhoneErrors, PostalCodeErrors, VatErrors } from './interfaces';

export type MinZipSchema =
    | {
          countryCode: ShippableCountry | '';
          zip: string;
      }
    | {
          countryCode?: ShippableCountry | '';
          zip: string;
      };

// const IRELAND_CHARS = '(?![BGIJLMOQSUZ])[A-Z]';
const IRELAND_CHARS = '[A-Z]';
// const BRITTAIN_CHARS = '(?![CIKMOV])[A-Z]';
const BRITTAIN_CHARS = '[A-Z]';

const countryZipRegex: Record<ShippableCountry, string> = {
    NL: '[0-9]{4}[A-Z]{2}',
    AT: '[0-9]{4}',
    BE: '[0-9]{4}',
    BG: '[0-9]{4}',
    HR: '[0-9]{5}',
    CY: '[0-9]{4}',
    CZ: '[1-7][0-9]{4}',
    DK: '[0-9]{4}',
    EE: '[0-9]{5}',
    FI: '[0-9]{5}',
    FR: '[0-9]{5}',
    DE: '[0-9]{2}|[0-9]{4,5}',
    GR: '[0-9]{5}',
    HU: '[0-9]{4}',
    IE: `${IRELAND_CHARS}[0-9]{2}(${IRELAND_CHARS}|[0-9]){4}`,
    IT: '[0-9]{5}',
    LV: 'LV-[0-9]{4}',
    LT: 'LT-[0-9]{5}',
    LU: '[0-9]{4}',
    MT: '[A-Z]{3}[0-9]{4}',
    PL: '[0-9]{2}-[0-9]{3}',
    PT: '[0-9]{4}(-[0-9]{3})?',
    RO: '[0-9]{5}',
    SK: '[890][0-9]{4}',
    SI: '(SI-)?[0-9]{4}',
    ES: '[0-9]{5}',
    SE: '[0-9]{5}',
    GB: `${BRITTAIN_CHARS}((${BRITTAIN_CHARS})|[0-9]){1,3}[0-9]${BRITTAIN_CHARS}{2}`,
    NO: '[0-9]{4}',
    AL: '[0-9]{4}',
    BA: '[0-9]{5}',
    // CA: '[A-Z][0-9][A-Z][0-9][A-Z][0-9]',
    IS: '[0-9]{3}',
    ME: '[0-9]{5}',
    MK: '[0-9]{4}',
    RS: '[0-9]{5}',
    // CL: '[0-9]{7}',
    // TR: '[0-9]{5}',
};
export const zipRegex = (countryCode: ShippableCountry) => `^${countryZipRegex[countryCode]}$`;

export const mailingAddressSchema = yup
    .object()
    .shape({
        address1: yup.string().required(),
        address2: yup.string().nullable(),
        city: yup.string().required(),
        company: yup.string().required(),
        countryCode: yup.string().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phone: yup.string().required(),
        zip: yup.string().required(),
    })
    .noUnknown()
    .strict();

function checkVatParams({
    t,
    value,
    params: { exactLength, minLength, maxLength, mustInclude },
}: {
    t?: TranslationFunction<VatErrors>;
    value: string;
    params: Omit<VatRegexParams, 'r'>;
}): string | undefined {
    if (mustInclude) {
        if (typeof mustInclude === 'number') {
            // Any <mustInclude> number of characters
            if (value.replace(/[^a-z]/gi, '').length !== mustInclude) {
                return t
                    ? t('missing_letters', { context: { length: mustInclude } })
                    : `VAT has missing letters ${mustInclude}`;
            }
        } else {
            // The character <mustInclude>
            if (!value.includes(mustInclude))
                return t
                    ? t('missing_substring', { context: { missingValue: mustInclude } })
                    : `VAT has missing substring ${mustInclude}`;
        }
    }

    if (typeof exactLength === 'number' && value.length !== exactLength) {
        return t ? t('wrong_length', { context: { length: exactLength } }) : `VAT does not have length ${exactLength}`;
    }
    // One of multiple lengths
    if (typeof exactLength === 'object' && !exactLength.includes(value.length)) {
        return t
            ? t('wrong_length', { context: { length: exactLength.join(t('conjunction')) } })
            : `VAT has incorrect length`;
    }
    if (minLength && value.length < minLength) {
        return t ? t('too_short', { context: { length: minLength } }) : 'VAT is too short';
    }
    if (maxLength && value.length > maxLength) {
        return t ? t('too_long', { context: { length: maxLength } }) : 'VAT is too long';
    }
    return undefined;
}

// Test vat format
yup.addMethod(yup.string, 'isVatFormat', function <
    ParentForm extends MinVatSchema,
>(t?: TranslationFunction<VatErrors>) {
    return yup.string().test(
        'isVatFormat',
        (
            value = '',
            {
                parent: { countryCode },
                createError,
            }: {
                parent: Pick<ParentForm, 'countryCode'>;
                createError: (params: yup.CreateErrorOptions) => yup.ValidationError;
            },
        ) => {
            if (!countryCode) {
                return createError({ message: t ? t('missing_country_code') : 'Missing country code' });
            }
            const formattedValue = value.toUpperCase().replaceAll(/[ -]/g, '');

            if (new RegExp(vatRegex(countryCode)).test(formattedValue)) return true;

            let countrySuggestion: string | undefined = undefined;
            for (const code of Object.keys(countryVatRegex)) {
                if (new RegExp(vatRegex(code as ShippableCountry)).test(formattedValue)) {
                    countrySuggestion = t
                        ? t('wrong_country', {
                              context: {
                                  countryCode: code,
                                  countryName: SHIPPABLE_COUNTRY_NAMES[code as ShippableCountry],
                              },
                          })
                        : 'Incorrect country';
                    break;
                }
            }

            const error = checkVatParams({
                t,
                value: formattedValue.replace(countryCode, ''),
                params: countryVatRegex[countryCode as ShippableCountry],
            });
            if (error || countrySuggestion) {
                return createError({
                    message:
                        countrySuggestion && error ? `${error} ${countrySuggestion}` : (countrySuggestion ?? error),
                });
            }
            return createError({ message: t ? t('invalid') : 'Invalid VAT number' });
        },
    );
});

// Test email
const emailRegex = new RegExp('^.+@.+\\..+$');
yup.addMethod(yup.string, 'isEmailFormat', function (message = 'Email format is invalid') {
    return this.test('isEmailFormat', message, (value?: string) => !value || emailRegex.test(value));
});

// Test phonenumber
export interface MinPhoneSchema {
    countryCallingCode: ShippableCountry | '';
    phone: string;
}

yup.addMethod(yup.string, 'isPhoneFormat', function <
    ParentForm extends MinPhoneSchema,
>(t?: TranslationFunction<PhoneErrors>) {
    return yup.string().test(
        'isPhoneFormat',
        (
            value = '',
            {
                parent: { countryCallingCode },
                createError,
            }: {
                parent: Pick<ParentForm, 'countryCallingCode'>;
                createError: (params: yup.CreateErrorOptions) => yup.ValidationError;
            },
        ) => {
            if (!value) {
                return createError({ message: t ? t('required') : 'Phone format is required' });
            }

            if (!formatPhone(value, countryCallingCode).phoneNumber) {
                return createError({
                    message: t
                        ? t('invalid', {
                              context: { phoneNumber: countryCallingCode + value },
                          })
                        : 'Phone format is invalid',
                });
            }

            return true;
        },
    );
});

// Test second password
yup.addMethod(yup.string, 'isPasswordConfirmation', function (message: string) {
    return this.test(
        'isPasswordConfirmation',
        message,
        (value?: string, { parent }: { parent?: { password?: string } } = {}) => parent?.password === value,
    );
});

// Test string in number format
yup.addMethod(
    yup.string,
    'isNumberFormat',
    function (message = 'String does not consist of only numbers', nullable = false) {
        return this.test(
            'isNumberFormat',
            message,
            (value?: string) => (nullable && !value) || (value && /^[0-9]+$/g.test(value)),
        );
    },
);

// Shopify ID check
export const shopifyIdCheck = {
    customer: new RegExp('^gid://shopify/Customer/[0-9]+$'),
    productVariant: new RegExp('^gid://shopify/ProductVariant/[0-9]+$'),
    order: new RegExp('^gid://shopify/Order/[0-9]+$'),
    address: new RegExp('^gid://shopify/MailingAddress/[0-9]+$'),
};
yup.addMethod(
    yup.string,
    'isShopifyId',
    function (type: keyof typeof shopifyIdCheck, message = 'ID is not a valid format') {
        return this.test('isShopifyId', message, (value?: string) => !value || shopifyIdCheck[type].test(value));
    },
);

// Postal code check
yup.addMethod(yup.string, 'isValidZip', function <
    ParentForm extends MinZipSchema = MinZipSchema,
>(t?: TranslationFunction<PostalCodeErrors>) {
    return yup.string().test(
        'isValidZip',
        (
            value = '',
            {
                parent: { countryCode },
                createError,
            }: {
                parent: Pick<ParentForm, 'countryCode'>;
                createError: (params: yup.CreateErrorOptions) => yup.ValidationError;
            },
        ) => {
            if (!value) return true;
            if (!countryCode) return createError({ message: t?.('help_example.zero') ?? 'No country present' });
            if (!new RegExp(zipRegex(countryCode)).test(value.toUpperCase().replaceAll(' ', ''))) {
                const context = {
                    countryCode,
                    countryName: SHIPPABLE_COUNTRY_NAMES[countryCode as ShippableCountry],
                    postalCodeExample: ZIP_EXAMPLE[countryCode as ShippableCountry],
                };
                return createError({
                    message: t
                        ? `${t('invalid')} ${t('help_example.one', { context })}`
                        : `Invalid zip format for ${countryCode}`,
                });
            }
            return true;
        },
    );
});

yup.addMethod(yup.mixed, 'maxFileSize', function maxFileSize(size: number, errorMessage?: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.test('size', errorMessage!, (value?: { size?: number }) => {
        if (!value) return true;
        return (value?.size ?? Infinity) <= size;
    });
});

type AnyPresentValue = NonNullable<unknown>;

declare module 'yup' {
    interface StringSchema<
        TType extends yup.Maybe<string> = string | undefined,
        TContext = yup.AnyObject,
        TDefault = undefined,
        TFlags extends yup.Flags = '',
    > extends yup.Schema<TType, TContext, TDefault, TFlags> {
        isEmailFormat(message?: string, nullable?: boolean): StringSchema<TType, TContext, TDefault, TFlags>;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isPhoneFormat<ParentForm extends MinVatSchema>(
            t?: TranslationFunction<PhoneErrors>,
        ): StringSchema<TType, TContext, TDefault, TFlags>;

        isNumberFormat(message?: string, nullable?: boolean): StringSchema<TType, TContext, TDefault, TFlags>;

        isShopifyId(
            type: keyof typeof shopifyIdCheck,
            message?: string,
        ): StringSchema<TType, TContext, TDefault, TFlags>;

        isPasswordConfirmation(message: string): StringSchema<TType, TContext, TDefault, TFlags>;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isVatFormat<ParentForm extends MinVatSchema>(
            t?: TranslationFunction<VatErrors>,
        ): StringSchema<TType, TContext, TDefault, TFlags>;

        isValidZip(t?: TranslationFunction<PostalCodeErrors>): StringSchema<TType, TContext, TDefault, TFlags>;
    }

    interface MixedSchema<
        TType extends yup.Maybe<AnyPresentValue> = AnyPresentValue | undefined,
        TContext = yup.AnyObject,
        TDefault = undefined,
        TFlags extends yup.Flags = '',
    > extends yup.Schema<TType, TContext, TDefault, TFlags> {
        maxFileSize(size: number, errorMessage?: string): MixedSchema<TType, TContext, TDefault, TFlags>;
    }
}

export { yup };
