import { ProductSearchResult } from '../interfaces/product';

type Product = {
    tags: string[];
    variants: {
        edges: {
            node: {
                quantityAvailable?: number;
            };
        }[];
    };
};

export enum ProductState {
    COMING_SOON = 'COMING_SOON',
    NEW_IN = 'NEW_IN',
    FAST_DELIVERY = 'FAST_DELIVERY',
    ALMOST_OUT_OF_STOCK = 'ALMOST_OUT_OF_STOCK',
    USED_MACHINE = 'USED_MACHINE',
}

export function getProductState(product: Product): ProductState | undefined {
    if (!product) return undefined;
    if (product.variants.edges[0].node.quantityAvailable === 0) {
        if (product.tags.includes('pc:used-machines')) {
            return ProductState.USED_MACHINE;
        }
        return ProductState.COMING_SOON;
    }
    return undefined;
}

export function productSearchExactMatch(
    products: Pick<ProductSearchResult, 'sku' | 'title' | 'handle' | 'vendor' | 'compatibility'>[],
    query: string,
): boolean {
    const formattedQuery = query.replace(/\s+/g, '').toLowerCase();
    return products.some((product) => {
        const { sku, title, handle, vendor, compatibility: { brands = [], models = [] } = {} } = product;
        const properties = [sku, title, handle, vendor, ...brands, ...models];
        return properties.some((property) => property?.replace(/\s+/g, '').toLowerCase().includes(formattedQuery));
    });
}
