export function capitalizeFirstLetter(word?: string | null) {
    if (!word) return;
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function capitalizeEveryWord(sentence?: string | null) {
    if (!sentence) return;
    return sentence
        .split(' ')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ');
}
