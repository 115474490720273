import { Environment } from '../constants/environment';
import { isEnvironment } from '../formatters/environment';

export function getCurrentEnv() {
    const loadedEnv = process.env.AZURE_ENV as Environment | undefined;
    return loadedEnv && isEnvironment(loadedEnv) ? loadedEnv : Environment.DEV;
}

const shopifyConfigProd = {
    storeDomain: 'https://velroq.myshopify.com',
};

const shopifyConfigDev = {
    storeDomain: 'https://velroq-dev.myshopify.com',
};

export function storeDomain(devMode: boolean) {
    return devMode ? shopifyConfigDev.storeDomain : shopifyConfigProd.storeDomain;
}

export function getEnvValue(key: string): string {
    const value = process.env[key];
    if (!value) throw new Error('Could not find environment variable with key: ' + key);
    return value;
}
