import { PathOf } from './PathOf';

interface RichText {
    children: {
        text: string;
    }[];
}

interface TranslationOptions<RT = string> {
    context?: Record<string, string | number | undefined>;
    defaultValue?: RT;
    allowUndefined?: boolean;
}

export type TranslationFunction<T> = <RT = string>(key: PathOf<T>, options?: TranslationOptions<RT>) => RT;

export function formatTranslationMock<T>(): TranslationFunction<T> {
    return <RT = string>(key: PathOf<T>) => key as RT;
}

function formatValue(translation: string | RichText, context: TranslationOptions['context']): string | RichText {
    if (typeof translation === 'object') {
        return {
            ...translation,
            children: translation.children?.map(({ text, ...other }: RichText['children'][number]) => ({
                ...other,
                text: formatValue(text, context) as string,
            })),
        };
    }
    const match = translation.replaceAll(/[^a-zA-Z{}]/g, '').match(/({{[a-zA-Z]+}})/g);
    if (context && match?.length) {
        for (const contextKey of match
            .map((k) => k.replaceAll(/[{}]/g, ''))
            .filter((k) => Object.keys(context).includes(k))) {
            translation = translation.replaceAll(`{{${contextKey}}}`, `${context[contextKey]}`);
        }
    }
    return translation;
}

export function formatTranslation<T>(data?: T): TranslationFunction<T> {
    return <RT = string>(
        key: PathOf<T>,
        { context, defaultValue, allowUndefined = false }: TranslationOptions<RT> = {},
    ): RT => {
        const translation =
            key
                .split('.')
                .reduce(
                    (data, field) =>
                        key && data && Object.keys(data).includes(field)
                            ? (data as Record<string, any>)?.[field]
                            : data,
                    data,
                ) ?? defaultValue;
        if (!translation || typeof translation !== 'string') {
            if (Array.isArray(translation)) return translation.map((v) => formatValue(v, context)) as RT;
            return (allowUndefined ? undefined : defaultValue ?? key) as RT;
        }
        return formatValue(translation, context) as RT;
    };
}

export function stepIn<T, KT>(f: TranslationFunction<T>, p: PathOf<T>): TranslationFunction<KT> {
    return <RT = string>(key: PathOf<KT>, params?: TranslationOptions<RT>): RT =>
        f(`${p}.${key}` as PathOf<T>, params) as RT;
}
